<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="mb-3">
            <div class="d-md-flex justify-content-between">
              <h3>Visit Tracker #{{ patientId }}</h3>
              <b-btn :to="{ name: 'Visit Tracker' }" variant="primary">
                <i class="fa fa-arrow-left mr-1"></i>
                Back
              </b-btn>
            </div>
          </div>

          <b-card title="Card Title" no-body>
            <b-card-header
              header-tag="nav"
              header-bg-variant="transparent"
              header-class="pt-4"
            >
              <b-nav card-header tabs justified>
                <b-nav-item
                  :to="{
                    name: 'Visit Tracker: General',
                    params: { patientId: patientId },
                  }"
                  exact
                  exact-active-class="active"
                >
                  General
                </b-nav-item>
                <b-nav-item
                  :to="{
                    name: 'Visit Tracker: Appointments',
                    params: { patientId: patientId },
                  }"
                  exact
                  exact-active-class="active"
                >
                  Appointments
                </b-nav-item>
              </b-nav>
            </b-card-header>

            <b-card-body>
              <router-view></router-view>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Analytics",
  props: ["patientId"],
};
</script>

<style>
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  text-align: left;
  width: 25%;
}
.table.b-table.b-table-stacked > tbody > tr > td {
  color: black;
}
</style>
